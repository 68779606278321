import { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from '../assets/img/lors_logo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';

export const NavBar = () => {
  //New
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleClick = () => {
    window.location.href = '/Camping';
  };
  //Old
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  useEffect(() => {
    setActiveLink(window.location.pathname.split("/")[1] || "home");
  }, [window.location.pathname]);

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* Home */}
            <Nav.Link href="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            {/* Camping Dropdown */}
            <NavDropdown show={dropdownOpen} onToggle={toggleDropdown} title={<a href="#" onClick={handleClick} style={{padding: "0px", paddingRight: "20px"}}>Camping</a>} id="basic-nav-dropdown" className={activeLink === 'Camping' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Camping')}>
              <NavDropdown title="North">
                  <NavDropdown.Item href="/Camping/north/kager">Kager</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/division">Division</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/pinkut">Pinkut</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/ethel-f-wilson">Ethel F Wilson</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/augier">Augier</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/pendleton-bay">Pendleton Bay</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/Taltapin">Taltapin</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/coop">Coop</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/richmond">Richmond</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/maxan">Maxan</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/agate-point">Agate Point</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/mclure-pit">Mclure Pit</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/govt-point">Govt Point</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/colleymount">Colleymount</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/north/noralee">Noralee</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="South">
                  <NavDropdown.Item href="/Camping/south/west-francois">West Francois</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/lund">Lund</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/wistaria">Wistaria</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/ootsa">Ootsa</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/chinook">Chinook</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/takysie">Takysie</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/indian-bay">Indian Bay</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/uncha">Uncha</NavDropdown.Item>
                  <NavDropdown.Item href="/Camping/south/binta">Binta</NavDropdown.Item>
              </NavDropdown>
                  
            </NavDropdown>
            {/* Map & About */}
            <Nav.Link href="/map" className={activeLink === 'map' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('map')}>Map</Nav.Link>
            {/* <Nav.Link href="/donations" className={activeLink === 'donations' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('donations')}>Donations</Nav.Link> */}
            <Nav.Link href="/sponsors" className={activeLink === 'sponsors' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('sponsors')}>Sponsors</Nav.Link>
            <Nav.Link href="/about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about')}>About</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.facebook.com/LakesOutdoorRecreationSociety" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
            </div>  
                <HashLink smooth to="/About#contactMe">
                  <button className="vvd"><span>Contact</span></button>
                </HashLink>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}