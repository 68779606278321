import React from "react";
import ReactPlayer from "react-player";

const VideoSlide = ({ video }) => {
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}> {/* 16:9 aspect ratio */}
      <ReactPlayer
        url={video.src}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
        }}
      />
    </div>
  );
};

export default VideoSlide;