import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/lors_logo.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import TrackVisibility from 'react-on-screen';
import 'animate.css';


export const Sponsors = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Beautiful.", "Stunning.", "Magnificent.", "Gorgeous.", "Breathtaking.", "Serene.", "Captivating.", "Mesmerizing.", "Enchanting.", "Majestic.", "Radiant.", "Picturesque.", "Impressive.", "Striking.", "Exquisite.", "Inspiring.", "Peaceful.", "Tranquil.", "Harmonious.", "Delightful."];
  const period = 1000;
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="about sponsors snapScrolling" id="about">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>Sponsors</h1>
                <p>Thanks for all the wonderful donations that make this possible:</p>
                  <ul style={{fontSize: 22}}>
                    <li>
                      Rio Tinto Alcan
                    </li>
                    <li>
                      Chinook Community Forest
                    </li>
                    <li>
                      Outdoor Recreation Fund BC
                    </li>
                    <li>
                      Recreation Sites and Trails BC
                    </li>
                    <li>
                      BC Parks
                    </li>
                  </ul>
                  <HashLink smooth to="/About#contactMe">
                  <button>Connect <ArrowRightCircle size={25} /></button>
                </HashLink>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}