import { Container} from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  return (
      <Container>
        <TrackVisibility>
            {({ isVisible }) =>
            <div className={isVisible ? "contactMeIn animate__animated animate__fadeIn": "contactMeIn"}>
                <h1>Feedback Welcome!</h1>
                <h2>LORS Volunteer Rick Moyer</h2>
                <h3>Email: rickmoyer27@gmail.com</h3>
                <h3>Phone: +1 (250) 251-5555</h3>
            </div>}
        </TrackVisibility>
      </Container>
  )
}
export default Contact;