import React, { useState, useEffect } from "react";
import { Carousel, Button, Row, Col, Container } from "react-bootstrap";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { CampsiteStatus } from "./CampsiteStatus.js";
import { CampsiteInfo } from "./CampsiteInfo.js";
import VideoSlide from "./VideoSlide";
import campsiteData from "./campsiteData.json";

function ImagePage({ item }) {
  const itemName = item
    .split("/")
    .pop()
    .replaceAll("-", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const [buttons, setButtons] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [carouselInterval, setCarouselInterval] = useState(3000); // Default interval

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  const importAll = (r) =>
    r.keys().map((item) => ({
      name: item.replace("./", ""),
      file: r(item),
    }));

  const toCamelCase = (str) =>
    str
      .split("/")
      .map((part) =>
        part
          .split("-")
          .map((subpart) => subpart.charAt(0).toUpperCase() + subpart.slice(1))
          .join("-")
      )
      .join("/");

  const normalizedItem = toCamelCase(item);

  const campsiteInfo = campsiteData[normalizedItem] || {
    statusItems: [],
    infoItems: [],
  };
  const { infoItems, statusItems } = campsiteInfo;

  let images = null;
  let videos = [];

  switch (`${item}`) {
    //Direction landing Pages
    case "north":
      images = importAll(require.context("../assets/img/campsites/North", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south":
      images = importAll(require.context("../assets/img/campsites/South", false, /\.(png|jpe?g|svg)$/));
      break;

    //North
    case "north/kager":
      images = importAll(require.context("../assets/img/campsites/North/Kager", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/division":
      images = importAll(require.context("../assets/img/campsites/North/Division", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/pinkut":
      images = importAll(require.context("../assets/img/campsites/North/Pinkut", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=DCxl4kv5HmQ" });
      break;
    case "north/ethel-f-wilson":
      images = importAll(require.context("../assets/img/campsites/North/Ethel F Wilson", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/augier":
      images = importAll(require.context("../assets/img/campsites/North/Augier", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/pendleton-bay":
      images = importAll(require.context("../assets/img/campsites/North/Pendleton Bay", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/taltapin":
      images = importAll(require.context("../assets/img/campsites/North/Taltapin", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=l6InqfwII9E" });
      break;
    case "north/coop":
      images = importAll(require.context("../assets/img/campsites/North/Coop", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/richmond":
      images = importAll(require.context("../assets/img/campsites/North/Richmond", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/maxan":
      images = importAll(require.context("../assets/img/campsites/North/Maxan", false, /\.(png|jpe?g|svg)$/));
      break;  
    //To Be Switched
    case "north/agate-point":
      images = importAll(require.context("../assets/img/campsites/North/Agate Point", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/mclure-pit":
      images = importAll(require.context("../assets/img/campsites/North/Mclure Pit", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=fn9Lji5zOMI" });
      break;
    case "north/govt-point":
      images = importAll(require.context("../assets/img/campsites/North/Govt Point", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/colleymount":
      images = importAll(require.context("../assets/img/campsites/North/Colleymount", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/noralee":
      images = importAll(require.context("../assets/img/campsites/North/Noralee", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/noralee/noralee-east":
      images = importAll(require.context("../assets/img/campsites/North/Noralee/Noralee East", false, /\.(png|jpe?g|svg)$/));
      break;
    case "north/noralee/noralee-west":
      images = importAll(require.context("../assets/img/campsites/North/Noralee/Noralee West", false, /\.(png|jpe?g|svg)$/));
      break;

    //South
    case "south/west-francois":
      images = importAll(require.context("../assets/img/campsites/South/West Francois", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/lund":
      images = importAll(require.context("../assets/img/campsites/South/Lund", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=kypP0Q3xybo" });
      break;
    case "south/wistaria":
      images = importAll(require.context("../assets/img/campsites/South/Wistaria", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=ZfT9_edExbY" });
      break;
    case "south/ootsa":
      images = importAll(require.context("../assets/img/campsites/South/Ootsa", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/chinook":
    images = importAll(require.context("../assets/img/campsites/South/Chinook", false, /\.(png|jpe?g|svg)$/));
    videos.push({ src: "https://www.youtube.com/watch?v=AqSsyXIhL_c" });
    
    break;
    case "south/takysie":
      images = importAll(require.context("../assets/img/campsites/South/Takysie", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/takysie/takysie-site-a":
      images = importAll(require.context("../assets/img/campsites/South/Takysie/Takysie Site A", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/takysie/takysie-site-b":
      images = importAll(require.context("../assets/img/campsites/South/Takysie/Takysie Site B", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/indian-bay":
      images = importAll(require.context("../assets/img/campsites/South/Indian Bay", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=XiRo6z_yc-A" });
      break;
    case "south/uncha":
      images = importAll(require.context("../assets/img/campsites/South/Uncha", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/binta":
      images = importAll(require.context("../assets/img/campsites/South/Binta", false, /\.(png|jpe?g|svg)$/));
      break;
    case "south/binta/binta-north":
      images = importAll(require.context("../assets/img/campsites/South/Binta/Binta North", false, /\.(png|jpe?g|svg)$/));
      videos.push({ src: "https://www.youtube.com/watch?v=4e4GsqYbJUM" });
      break;
    case "south/binta/binta-south":
      images = importAll(require.context("../assets/img/campsites/South/Binta/Binta South", false, /\.(png|jpe?g|svg)$/));
      break;

    //Default Case
    default:
      console.log("Invalid category");
  }
  
  //Display Buttons Conditionally for Multiple Categories
  useEffect(() => {
    const categories = ["North", "South", "Binta", "Noralee", "Takysie"];
    setButtons(categories.includes(itemName));
  }, [item]);

  const handleImageClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    if (x >= rect.width * 0.25 && x <= rect.width * 0.75) {
      toggleFullscreen();
    }
  };

  const handleSlideChange = (index) => {
    const isVideo = index >= images.length; // Videos come after images
    setCarouselInterval(isVideo ? null : 3000); // Disable auto-rotation for videos
  };

  return (
    <div className={`snapScrolling imagepg ${fullscreen ? "fullscreen" : ""}`}>
      <div>
        <h1>{itemName}</h1>
      </div>
      {buttons ? (
        <Container>
          <Row>
            <Col size={12}>
              <div className="imagepg-buttons">
                {images.map((image) => (
                  <Button
                    href={`/Camping/${item}/${image.name
                      .toLowerCase()
                      .replace(".png", "")
                      .replace(".jpg", "")}`}
                    key={image.name}
                    style={{
                      borderRadius: "50%",
                      width: "300px",
                      height: "300px",
                      paddingTop: "130px",
                      margin: "10px",
                      background: `url(${image.file})`,
                      backgroundSize: "cover",
                      border: "none",
                      fontSize: "35px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "white",
                      textShadow:
                        "-1px 0px 0px black, 1px 0px 0px black, 0px -1px 0px black, 0px 1px 0px black",
                      transition: "color 0.1s, text-shadow 0.1s",
                    }}
                  >
                    {image.name.replace(".jpg", "").replace(".png", "").replaceAll("-", " ")}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className={"carouselContainer"}>
          <Carousel
            className={fullscreen ? "fullscreen" : ""}
            onClick={handleImageClick}
            interval={carouselInterval}
            onSlide={handleSlideChange}
          >
            {images.map((image, index) => (
              <Carousel.Item key={`image-${index}`}>
                <img className="d-block w-100" src={image.file} alt={image.name} />
              </Carousel.Item>
            ))}
            {videos.map((video, index) => (
              <Carousel.Item key={`video-${index}`}>
                <VideoSlide video={video} />
              </Carousel.Item>
            ))}
          </Carousel>
          {!fullscreen && (
            <div className={"siteInfo snapScrolling"}>
              <CampsiteInfo infoItems={infoItems} />
              <CampsiteStatus statusItems={statusItems} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ImagePage;