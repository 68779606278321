import React, { useState, useRef, useEffect } from "react";
import mapImage from "../assets/img/map.jpg"; // Correct import statement
import { Container } from "react-bootstrap";

export const Map = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [scale, setScale] = useState(1);
  const [transformOrigin, setTransformOrigin] = useState("center center");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);
  const lastTouchDistance = useRef(null);
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);
  const lastTap = useRef(0); // Track the last tap time for double-click detection
  const lastTouchTime = useRef(0); // Track the last touch time for mobile double-tap detection

  const toggleFullScreen = () => {
    if (isFullScreen) {
      // Exit fullscreen, reset zoom (scale to 1)
      setIsFullScreen(false);
      setScale(1); // Reset zoom on exit
      setTransformOrigin("center center");
      setPosition({ x: 0, y: 0 });
    } else {
      // Enter fullscreen mode without affecting zoom
      setIsFullScreen(true);
    }
  };

  // Single-click logic to detect double-click manually (desktop)
  const handleClick = (e) => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 300; // Maximum time between taps for double-tap
    if (now - lastTap.current < DOUBLE_TAP_DELAY) {
      // Double-click detected
      toggleFullScreen();
    } else {
      // First tap, update lastTap time
      lastTap.current = now;
    }
  };

  // Handle touch events for mobile double-tap detection
  const handleTouchStart = (e) => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 300; // Maximum time between taps for double-tap

    if (now - lastTouchTime.current < DOUBLE_TAP_DELAY) {
      // Double-tap detected on mobile, toggle fullscreen
      toggleFullScreen();
    } else {
      // First touch, update lastTouchTime
      lastTouchTime.current = now;
    }
    e.preventDefault(); // Prevent default touch behavior (e.g., zooming)
  };

  const handleWheel = (e) => {
    if (!isFullScreen) return;
    e.preventDefault();

    const rect = mapRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left; // Mouse X relative to the map
    const offsetY = e.clientY - rect.top; // Mouse Y relative to the map
    const originX = (offsetX / rect.width) * 100; // Percentage relative to the map
    const originY = (offsetY / rect.height) * 100;

    setTransformOrigin(`${originX}% ${originY}%`);
    setScale((prevScale) => {
      const newScale = prevScale + e.deltaY * -0.001; // Adjust zoom speed
      return Math.min(Math.max(newScale, 1), 5); // Clamp scale between 1 and 5
    });
  };

  const handleTouchMove = (e) => {
    if (!isFullScreen || e.touches.length < 2) return;

    const rect = mapRef.current.getBoundingClientRect();
    const touch1 = e.touches[0];
    const touch2 = e.touches[1];

    // Calculate the pinch center
    const centerX = (touch1.pageX + touch2.pageX) / 2;
    const centerY = (touch1.pageY + touch2.pageY) / 2;

    const offsetX = centerX - rect.left; // Center X relative to the map
    const offsetY = centerY - rect.top; // Center Y relative to the map
    const originX = (offsetX / rect.width) * 100; // Percentage relative to the map
    const originY = (offsetY / rect.height) * 100;

    setTransformOrigin(`${originX}% ${originY}%`);

    const currentDistance = Math.hypot(
      touch2.pageX - touch1.pageX,
      touch2.pageY - touch1.pageY
    );

    if (lastTouchDistance.current != null) {
      const scaleChange = currentDistance / lastTouchDistance.current;
      setScale((prevScale) => {
        const newScale = prevScale * scaleChange;
        return Math.min(Math.max(newScale, 1), 5); // Clamp scale between 1 and 5
      });
    }

    lastTouchDistance.current = currentDistance;
  };

  const handleTouchEnd = () => {
    lastTouchDistance.current = null; // Reset touch distance when fingers are lifted
  };

  // Dragging functionality for mouse or touch
  const handleMouseDown = (e) => {
    if (!isFullScreen) return;

    isDragging.current = true;
    lastMousePosition.current = { x: e.clientX, y: e.clientY };
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current || !isFullScreen) return;

    const dx = e.clientX - lastMousePosition.current.x;
    const dy = e.clientY - lastMousePosition.current.y;

    setPosition((prevPosition) => ({
      x: prevPosition.x + dx,
      y: prevPosition.y + dy,
    }));

    lastMousePosition.current = { x: e.clientX, y: e.clientY };
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleTouchStartDrag = (e) => {
    if (!isFullScreen) return;

    isDragging.current = true;
    lastMousePosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    e.preventDefault();
  };

  const handleTouchMoveDrag = (e) => {
    if (!isDragging.current || !isFullScreen) return;

    const dx = e.touches[0].clientX - lastMousePosition.current.x;
    const dy = e.touches[0].clientY - lastMousePosition.current.y;

    setPosition((prevPosition) => ({
      x: prevPosition.x + dx,
      y: prevPosition.y + dy,
    }));

    lastMousePosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  };

  const handleTouchEndDrag = () => {
    isDragging.current = false;
  };

  useEffect(() => {
    const mapElement = mapRef.current;

    if (mapElement) {
      mapElement.addEventListener("wheel", handleWheel);
      mapElement.addEventListener("touchmove", handleTouchMove);
      mapElement.addEventListener("touchend", handleTouchEnd);
      mapElement.addEventListener("mousedown", handleMouseDown);
      mapElement.addEventListener("mousemove", handleMouseMove);
      mapElement.addEventListener("mouseup", handleMouseUp);
      mapElement.addEventListener("touchstart", handleTouchStartDrag);
      mapElement.addEventListener("touchmove", handleTouchMoveDrag);
      mapElement.addEventListener("touchend", handleTouchEndDrag);
      mapElement.addEventListener("click", handleClick); // Use click for manual double-tap detection
      mapElement.addEventListener("touchstart", handleTouchStart); // Handle touchstart for mobile double-tap
    }

    return () => {
      if (mapElement) {
        mapElement.removeEventListener("wheel", handleWheel);
        mapElement.removeEventListener("touchmove", handleTouchMove);
        mapElement.removeEventListener("touchend", handleTouchEnd);
        mapElement.removeEventListener("mousedown", handleMouseDown);
        mapElement.removeEventListener("mousemove", handleMouseMove);
        mapElement.removeEventListener("mouseup", handleMouseUp);
        mapElement.removeEventListener("touchstart", handleTouchStartDrag);
        mapElement.removeEventListener("touchmove", handleTouchMoveDrag);
        mapElement.removeEventListener("touchend", handleTouchEndDrag);
        mapElement.removeEventListener("click", handleClick); // Use click for manual double-tap detection
        mapElement.removeEventListener("touchstart", handleTouchStart); // Handle touchstart for mobile double-tap
      }
    };
  }, [isFullScreen]);

  return (
    <section
      ref={mapRef}
      className={`map snapScrolling ${isFullScreen ? "fullScreen" : ""}`}
      id="map"
      style={{
        transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        transformOrigin: transformOrigin,
      }}
    >
      <div class="mapTitle">
        <h1>Map</h1>
      </div>
      <Container className="mapContainer snapScrolling">
        <div className="mapDiv">
          <img src={mapImage} alt="Map" />
        </div>
        {!isFullScreen && (
          <>
            <div className="mapDownload">
              <a href={mapImage} download="map.jpg" style={{ textDecorationColor: 'white' }}>
                <h2>Download</h2>
              </a>
            </div>
            <div className="mapInstructions">
              <h2>Instructions</h2>
              <ul>
                <li>Double click to Fullscreen</li>
                <li>Scroll / Pinch to Zoom</li>
                <li>Click and Drag to Pan</li>
              </ul>
            </div>
          </>
        )}
      </Container>
    </section>
  );
};