import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/lors_logo.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { HashLink } from "react-router-hash-link";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Adventure Awaits!", "31 Stunning Sites.", "15 Lovely Lakes.", "All Totally Free!", "Explore Today.", "Beautiful.", "Stunning.", "Magnificent.", "Gorgeous.", "Breathtaking.", "Serene.", "Captivating.", "Mesmerizing.", "Enchanting.", "Majestic.", "Radiant.", "Picturesque.", "Striking.", "Exquisite.", "Inspiring.", "Peaceful.", "Tranquil.", "Harmonious.", "Serene.", "Pristine."];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner snapscrolling" id="home">
      <Container>
        <Row className="aligh-items-center p-0">
          <Col xs={12} md={6} xl={7} className="p-0">
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>Lakes Outdoor Recreation Society</h1>
                <h3>Relax, Recharge, Reconnect.</h3>
                <p>Run by volunteers.</p>
                <h2><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Rotatoes", "Returning", "Riggidly" ]'><span className="wrap">{text}</span></span></h2>
                <HashLink smooth to="/About#contactMe">
                  <button>Connect <ArrowRightCircle size={25} /></button>
                </HashLink>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5} className="main-logo p-0">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "bannerLogo animate__animated animate__zoomIn" : "bannerLogo"}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}