import { Container, Row, Col, Tab} from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Welcome = () => {
  return (
    <section className="welcome snapScrolling" id="welcome">
      <Container>
        <TrackVisibility>
            {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h1>Gateway to Tweedsmuir Park</h1>
                {/* // <h1 className="txt-center">To</h1>
                // <h1 className="txt-right">Tweedsmuir</h1> */}
            </div>}
        </TrackVisibility>
      </Container>
    </section>
  )
}